import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";

import { CurrentUserContext } from "../../providers/auth";
import { useSettings } from "../../hooks";

import Layout from "../../components/layout";
import Loading from "../../components/loading";

const LogoutCallbackPage = () => (
    <Layout checkAuth={false} seoTitle="Loading...">
        <Callback />
    </Layout>
);

export default LogoutCallbackPage;

const Callback = () => {
    const { grfLogoutUrl } = useSettings();
    const currentUser = useContext(CurrentUserContext);
    const [triggerGrfLogout, setTriggerGrfLogout] = useState(false);

    useEffect(() => {
        if (currentUser) {
            currentUser
                .signOutCallback()
                .catch((err) => console.error(err))
                .finally(() => setTriggerGrfLogout(true));
        }
    }, [currentUser]);

    return (
        <>
            <Loading />
            {triggerGrfLogout && (
                <iframe
                    className="hidden h-px w-full"
                    src={grfLogoutUrl}
                    onLoad={() => navigate("/")}
                ></iframe>
            )}
        </>
    );
};
